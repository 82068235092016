import React from 'react'
import Layout from '../components/App/Layout'
import SEO from '../components/App/SEO';
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import { graphql, Link } from 'gatsby'
import visuallyAppealingImg from '../assets/images/features/Visually-Appealing.webp'

export default function Template(props) {
    const post = props.pageContext;
    let postHTML = post.html;

    if(postHTML.includes("image-right")){
      var n = postHTML.indexOf('width::')+8;
      var res = postHTML.substring(n, n+3);

      if(n > 7){
        postHTML = postHTML.replace(/<p>{image-righ.{3,}.}/g, `<span style='width:${res}px' class='test-img'>`);
        
      }else{
        postHTML = postHTML.replace(/<p>{image-righ.{3,}.}/g, "<span class='test-img'>");
      }
      postHTML = postHTML.replace(/{:image-right}<\/p>/g, "</span>");
    }

    if(postHTML.includes("image-left")){
      var n = postHTML.indexOf('width::')+8;
      var res = postHTML.substring(n, n+3);

      if(n > 7){
        postHTML = postHTML.replace(/<p>{image-left.{3,}.}/g, `<span style='width:${res}px' class='test-img'>`);
        
      }else{
        postHTML = postHTML.replace(/<p>{image-left.{3,}.}/g, "<span class='test-img'>");
      }
      postHTML = postHTML.replace(/{:image-left}<\/p>/g, "</span>");
    }

  return (
    <Layout location="" env={props.data.site.siteMetadata.env}>
    <Navbar />
        <div className="blog-post bg-nav">
          <div className="container">
            <SEO 
              postTitle={post.frontmatter.metaTitle + " | BlueMail App"}
              postDescription={post.frontmatter.metaDescription}
              postImage={post.frontmatter.ogImage ? post.frontmatter.ogImage : post.frontmatter.image}
              postURL={`blog/${post.frontmatter.path}`}
            />
            {post.frontmatter.publishToProd && <div className='col-12'>
                  {
                  (post.frontmatter.image) && (
                      <img src={post.frontmatter.image} style={{width: '100%'}} alt={`BlueMail ${post.frontmatter.blogTitle}`} />
                  ) 
                  }
                  <div className='blog-content' style={{marginTop: '45px'}}>
                  <h2>{post.frontmatter.blogTitle}</h2>
                  
                  {/* {moment(post.frontmatter.date).format('ll')} */}
                  
                  <div id="post-html" className='posta' dangerouslySetInnerHTML={{ __html: postHTML }} />
                  
                </div>
              </div>
            }
            {!post.frontmatter.publishToProd && <div className="container pt-120 pb-100">
                <div className="not-found-area">
                  <div>
                      <img style={{maxWidth: "100%"}} src={visuallyAppealingImg} alt="visuallyAppealingImg" />
                  </div>
                  <div style={{padding: '0 30px'}}>
                      <h1 className="mb-20" style={{fontSize: '3rem', fontWeight: 'normal'}}>No blog found!</h1>
                      <h1 className="mb-30" style={{fontSize: '2rem', fontWeight: 'normal'}}>Please check Blog Pages.</h1>
                      <Link to='/blog' className='default-btn'>Back to Blog Pages</Link>
                  </div>
                  
                  </div>
                </div>
              }
          </div>
        </div>
    <Footer />
    </Layout>
  )
}

export const query = graphql`
query TemplatePageQuery {
  site {
      siteMetadata {
        title
        env
      }
    }
  }
`
